import React, { useEffect, useState } from 'react'
import PopupForm from './popupForm'
export default function Popup({ children }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setOpen(false) 
        }, 10_000)
        window.hbspt.forms.create({
          region: "na1",
          portalId: "9226978",
          formId: "b8e95183-1211-48f5-bc20-b0294b479a1b",
          target: "#popup-form",
        })
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setOpen(false)
            }
        })
        window.removeEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setOpen(false)
            }
        })
    }, [])

    return (
        <>
            <div className={'popup ' + (open ? "open" : '')} >
                <div className='popup-inner'>
                    <div className='grid pb40 w-100 m-pb20'>
                        <div />
                        <div className='flex justify-center '>
                            <h4 className='m0 text-300'>SUBSCRIBE</h4>
                        </div>
                        <div className='flex justify-end'>
                            <button className='flex justify-end ' onClick={() => setOpen(false)}>
                                <svg width="16" height="16" viewBox="0 0 16 16" stroke="black" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="3.88895" y1="3.88912" x2="11.6671" y2="11.6673" />
                                    <line x1="3.88895" y1="3.88912" x2="11.6671" y2="11.6673" />
                                    <line x1="3.88888" y1="11.6673" x2="11.6671" y2="3.88915" />
                                    <line x1="3.88888" y1="11.6673" x2="11.6671" y2="3.88915" />
                                </svg>

                            </button>
                        </div>

                    </div>

                    <h2 className='max-450 text-center h2 m0 pb40 m-pb20'>We would love to share the following things with you:
                    </h2>
                    <p className='max-550 small op-50 pb40 m0 m-pb20'>
                        <ul className='m0'>
                            <li>
                                Our monthly newsletter containing company and project-based news.
                            </li>
                            <li>
                                Local Brisbane, Gold Coast and general market updates
                            </li>
                            <li>
                                Industry reports and insights
                            </li>
                        </ul>
                    </p>

                    <div id='popup-form' className='m0 text-center w-100'/>

                </div>
            </div>
            {children}
        </>
    )
}
